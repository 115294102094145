import { SanityStyledContent } from "../components/StyledContent/StyledContent";
import { SanityUnitOfWork } from "./lessons";
import {
  SanityBlockQuote,
  SanityCallToAction,
  SanityCampaign,
  SanityCardList,
  SanityImage,
  SanityInternalLink,
  SanityRawContent,
  SanityTabs,
  SanityYoutubeVideo
} from "./common";
import { SanityMake } from "./makes";

export interface SanityLandingPageTitleBoxHero {
  _key: string;
  _type: "landingPageTitleBoxHero";
  content: SanityRawContent;
  image: SanityImage;
  layout: "full-width-image" | "custom-new-microbit" | "custom-primary";
}

export interface SanityMainContentCard {
  _key: string;
  _type: "landingPageContentCard";
  content: SanityRawContent;
  layout?: "wide-narrow-text" | "narrow-full-text";
}

export interface SanityGettingStartedContentCard {
  _key: string;
  _type: "gettingStartedPageContentCard";
  content: SanityRawContent;
}

export type CustomBlockCompontent =
  | SanityBlockQuote
  | SanityCallToAction
  | SanityCardList
  | SanityImage
  | SanityLandingPageString
  | SanityStyledContent
  | SanityTabs
  | SanityYoutubeVideo;

export interface SanityLandingPageString {
  value: string;
}

export interface SanityLandingPageCustomBlock {
  _type: "landingPageCustomBlock";
  _key: string;
  /**
   * An ad-hoc notion of type.
   *
   * We used these for one-off components, sacrificing some type safety for flexibility.
   * For the sake of bundle size we create a new page template when we use such components.
   */
  type?: string;
  title?: string;
  subtitle?: string;
  content: Array<{
    /**
     * As above.
     */
    type: string;
    /**
     * Often a single value. Sanity's type system doesn't allow non-array unions.
     */
    content: CustomBlockCompontent[];
  }>;
}

export const findContent = <T extends CustomBlockCompontent>(
  type: string,
  content: Array<{ type: string; content: CustomBlockCompontent[] }>
): T[] => {
  return findArrayContent(type, content).flat() as T[];
};

export const findArrayContent = (
  type: string,
  content: Array<{ type: string; content: CustomBlockCompontent[] }>
): CustomBlockCompontent[][] => {
  const matches = content.filter(x => x.type === type);
  if (matches.length === 0) {
    return [];
  }
  return matches.map(item => item.content);
};

export interface SanityLandingPageHomePageIntro {
  _type: "landingPageHomePageIntro";
  _key: string;
  content: SanityRawContent;
  topImageDesktop: SanityImage;
  topImageMobile: SanityImage;
  callToActionText: string;
  callToActionLink: SanityInternalLink;
  bottomImage: SanityImage;
}

export interface SanityLandingPageCardsContentBlock {
  _type: "landingPageCardsContentBlock";
  _key: string;
  content: SanityRawContent;
  cards: (SanityUnitOfWork | SanityMake)[];
}

export interface SanityLandingPageImageContentBlock {
  _type: "landingPageImageContentBlock";
  _key: string;
  content: SanityRawContent;
  style: "image-left" | "image-right" | "logo";
  background: "blue" | "red" | "green";
  image: SanityImage;
}

export interface SanityLandingPageCarousel {
  _type: "landingPageCarousel";
  _key: string;
  carousel: {
    items: SanityCampaign[];
  };
}

export type LandingPageItem =
  | SanityMainContentCard
  | SanityLandingPageCustomBlock
  | SanityLandingPageTitleBoxHero
  | SanityLandingPageHomePageIntro
  | SanityLandingPageCardsContentBlock
  | SanityLandingPageImageContentBlock
  | SanityLandingPageCarousel;

export type ComponentWidth = "full" | "default";
